import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Cart Context
const CartContext = createContext();

// Provide the CartContext to your components
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const loadCartItems = () => {
        const savedCartItems = sessionStorage.getItem('cartItems');
        return savedCartItems ? JSON.parse(savedCartItems) : [];
    };

    const [cartItems, setCartItems] = useState(loadCartItems);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        if (cartItems.length > 0) {
            sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
        } else {
            sessionStorage.removeItem('cartItems');
        }
    }, [cartItems]);

    // Calculate the total price for the cart item based on selected options and quantity
    const calculateTotalPrice = (cartItem) => {
        let totalPrice = parseFloat(cartItem.price);

        if (cartItem.selectedRequiredOption) {
            totalPrice += parseFloat(cartItem.selectedRequiredOption.option_price);
        }

        cartItem.selectedOptionalOptions.forEach((option) => {
            totalPrice += parseFloat(option.option_price);
        });

        return totalPrice * cartItem.quantity;
    };

    // Add item to the cart
    const addToCart = (item) => {
        if (!item || !item.id) {
            console.error('Item or item ID is missing:', item);
            return;
        }

        const { quantity, selectedRequiredOption, selectedOptionalOptions } = item;

        // Function to check if two items are the same, considering options as well
        const isSameItem = (cartItem, item) => {
            const isRequiredOptionSame =
                JSON.stringify(cartItem.selectedRequiredOption) === JSON.stringify(item.selectedRequiredOption);
            const areOptionalOptionsSame =
                JSON.stringify(cartItem.selectedOptionalOptions) === JSON.stringify(item.selectedOptionalOptions);

            return cartItem.item.id === item.id && isRequiredOptionSame && areOptionalOptionsSame;
        };

        setCartItems((prevItems) => {
            const existingItem = prevItems.find((cartItem) => isSameItem(cartItem, item));

            if (existingItem) {
                // If the item with the same ID and options exists, update the quantity and price
                return prevItems.map((cartItem) =>
                    isSameItem(cartItem, item)
                        ? {
                            ...cartItem,
                            quantity: cartItem.quantity + quantity,
                            totalPrice: cartItem.totalPrice + calculateTotalPrice(item),
                            selectedRequiredOption,
                            selectedOptionalOptions,
                        }
                        : cartItem
                );
            } else {
                // If no matching item, add it as a new item to the cart
                return [
                    ...prevItems,
                    {
                        item,
                        quantity,
                        totalPrice: calculateTotalPrice(item),
                        selectedRequiredOption,
                        selectedOptionalOptions,
                    },
                ];
            }
        });

        setSuccessMessage('Item successfully added to the cart!');
    };

    // Update the quantity of an item in the cart
    const updateCartItemQuantity = (itemId, newQuantity) => {
        setCartItems((prevItems) =>
            prevItems.map((cartItem) =>
                cartItem.item.id === itemId
                    ? { ...cartItem, quantity: newQuantity, totalPrice: calculateTotalPrice({ ...cartItem.item, quantity: newQuantity }) }
                    : cartItem
            )
        );
    };

    const removeItemFromCart = (itemId) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.item.id !== itemId));
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addToCart,
                removeItemFromCart,
                updateCartItemQuantity,
                clearCart,
                successMessage,
                clearSuccessMessage,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
