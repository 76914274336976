import React, { useState, useEffect } from 'react';
import AgeConfirmationModal from '../components/AgeConfirmationModal';
import './OrderPage.css';
import { useCart } from '../context/CartContext';
import ItemModal from '../components/ItemModal';

const OrderPage = () => {
    const [categories, setCategories] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [activeCategories, setActiveCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [pendingCategory, setPendingCategory] = useState(null);
    const [isAgeConfirmed, setIsAgeConfirmed] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { successMessage, clearSuccessMessage } = useCart();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [scrollAfterClick, setScrollAfterClick] = useState(false);

    const restrictedCategories = ['9', '11', '12'];

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchCategories(), fetchMenuItems()]);
            setIsLoading(false);
        };
        fetchData();
    }, []);
    
    useEffect(() => {
        if (scrollAfterClick && activeCategories.length === 1) {
            scrollToCategory(activeCategories[0]);
            setScrollAfterClick(false); 
        }
    }, [scrollAfterClick, activeCategories]);
    
    useEffect(() => {
        if (showSuccessMessage) {
            const timer = setTimeout(() => {
                clearSuccessMessage();
                setShowSuccessMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessMessage, clearSuccessMessage]);

    const fetchCategories = async () => {
        try {
            const response = await fetch('/php/categories.php');
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchMenuItems = async () => {
        try {
            const response = await fetch('/php/menu-items.php');
            if (!response.ok) {
                throw new Error('Failed to fetch menu items');
            }
            const data = await response.json();
            setMenuItems(data);
        } catch (error) {
            console.error('Error fetching menu items:', error);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    
        if (query === '') {
            setActiveCategories([]);
        } else {
            const matchingCategories = categories
                .filter((category) =>
                    getMenuItemsForCategory(category.id).some((item) =>
                        item.item_name.toLowerCase().includes(query)
                    )
                )
                .map((category) => category.id);
    
            setActiveCategories(matchingCategories);
        }
    };
    
    const handleCategoryClick = (categoryId) => {
        if (!isAgeConfirmed && restrictedCategories.includes(categoryId)) {
            setShowModal(true);
            setPendingCategory(categoryId);
        } else {
            toggleAccordion(categoryId);
            setScrollAfterClick(true);
        }
    };
    
    const toggleAccordion = (categoryId) => {
        setActiveCategories((prevActive) => {
            if (prevActive.includes(categoryId)) {
                return prevActive.filter((id) => id !== categoryId);
            }
            return [categoryId]; 
        });
    };
    
    const handleModalConfirm = (confirmed) => {
        setShowModal(false);
        if (confirmed) {
            setIsAgeConfirmed(true);
            if (pendingCategory !== null) {
                toggleAccordion(pendingCategory);
                setScrollAfterClick(true);
                setPendingCategory(null); 
            }
        }
    };
    
    const getFilteredCategories = () => {
        if (!searchQuery) {
            return categories;
        }
        return categories.filter((category) =>
            getMenuItemsForCategory(category.id).some((item) =>
                item.item_name.toLowerCase().includes(searchQuery)
            )
        );
    };

    const getMenuItemsForCategory = (categoryId) => {
        return menuItems.filter((item) => item.category_id === categoryId);
    };

    const handleAddToCart = (item) => {
        setSelectedItem(item);
    };

    const filteredCategories = getFilteredCategories();

    const NAVBAR_HEIGHT = 85;

    const scrollToCategory = (categoryId) => {
        const categoryElement = document.getElementById(`category-${categoryId}`);
        if (categoryElement) {
            const rect = categoryElement.getBoundingClientRect();
            const topOffset = window.pageYOffset + rect.top - NAVBAR_HEIGHT;
            setTimeout(() => {
                window.scrollTo({ top: topOffset, behavior: 'smooth' });
            }, 100); 
        }
    };

    return (
        <div className='order-page-container'>
            {showSuccessMessage && successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}

            <p className="order-notice">
                We are accepting online pick up orders! Select your food, place your order, make payment by transfer then pick up from our restaurant.
            </p>

            <p className="order-notice allergy-notice">
                For allergies, please contact us.
            </p>

            <input
                type="text"
                className="search-input"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearch}
            />

            {/* Error and loading notices */}
            {isLoading && <p className='no-items'>Loading items...</p>}

            {categories.length === 0 && !isLoading && <p className='no-items'>No categories found</p>}

            {searchQuery && filteredCategories.length === 0 && (
                <p className='no-items'>No results found for "{searchQuery}"</p>
            )}

            <div className="order-accordion">
                {filteredCategories.map((category) => (
                    <div
                        key={category.id}
                        id={`category-${category.id}`}
                        className="order-accordion-item"
                    >
                        <div
                            className={`order-accordion-header ${activeCategories.includes(category.id) ? 'active' : ''}`}
                            onClick={() => handleCategoryClick(category.id)}
                        >
                            <span className="category-name">{category.category_name}</span>
                            <span className="accordion-icon">
                                {activeCategories.includes(category.id) ? '-' : '+'}
                            </span>
                        </div>
                        {activeCategories.includes(category.id) && (
                            <div className="order-accordion-body">
                                <ul className="order-menu-list">
                                    {getMenuItemsForCategory(category.id)
                                        .filter((item) => item.item_name.toLowerCase().includes(searchQuery))
                                        .map((item) => (
                                            <li key={item.id} className="order-menu-item">
                                                <img
                                                    src={`/uploads/${item.image}`}
                                                    alt={item.item_name}
                                                    className="order-menu-item-image"
                                                />
                                                <div className="order-menu-item-details">
                                                    <h3>{item.item_name}</h3>
                                                    <p className="menu-item-description">{item.item_description}</p>

                                                    {Number(item.in_stock) === 0 && (
                                                        <div className="out-of-stock-ticker">
                                                            <span>Sold Out</span>
                                                        </div>
                                                    )}

                                                    <div className="menu-item-bottom">
                                                        {item.price > 0 ? (
                                                            <p className="menu-item-price">£{item.price}</p>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <div
                                                            className="add-to-cart-icon"
                                                            onClick={() => handleAddToCart(item)}
                                                        >
                                                            <i className="fas fa-shopping-cart cart-btn"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}

                {showModal && <AgeConfirmationModal onConfirmAge={handleModalConfirm} />}

                {selectedItem && (
                    <ItemModal
                        isOpen={!!selectedItem}
                        item={selectedItem}
                        onClose={() => setSelectedItem(null)}
                        onSuccess={() => setShowSuccessMessage(true)}
                    />
                )}
            </div>
        </div>
    );
};

export default OrderPage;