import React, { useState } from 'react';
import './ItemModal.css';
import { useCart } from '../context/CartContext';

const ItemModal = ({ isOpen, item, onClose, onSuccess }) => {
    const [selectedRequiredOption, setSelectedRequiredOption] = useState(null);
    const [selectedOptionalOptions, setSelectedOptionalOptions] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [error, setError] = useState("");

    const { addToCart } = useCart();

    if (!isOpen || !item) return null;

    // Handle changes for required options
    const handleRequiredChange = (option) => {
        setSelectedRequiredOption(option);
    };

    // Handle changes for optional options
    const handleOptionalChange = (option) => {
        const optionId = parseInt(option.id, 10);
        const isSelected = selectedOptionalOptions.find((opt) => parseInt(opt.id, 10) === optionId);

        const hasSpecialOptions = ["1", "2", "3", "4", "5"].every((requiredId) =>
            item.optional_options.some((opt) => opt.id === requiredId)
        );

        // If not all special options (1-5) are present, proceed as normal
        if (!hasSpecialOptions) {
            if (isSelected) {
                setSelectedOptionalOptions(selectedOptionalOptions.filter((opt) => opt.id !== option.id));
            } else {
                setSelectedOptionalOptions([...selectedOptionalOptions, option]);
            }
            return;
        }

        // Special handling for items with options 1-5
        if (isSelected) {
            // Deselect the option if it's already selected
            setSelectedOptionalOptions(selectedOptionalOptions.filter((opt) => opt.id !== option.id));
        } else {
            // For options 1-3, allow only two selections
            const selectedCount = selectedOptionalOptions.filter((opt) => ["1", "2", "3"].includes(opt.id)).length;

            if (["1", "2", "3"].includes(option.id)) {
                if (selectedCount < 2) {
                    setSelectedOptionalOptions([...selectedOptionalOptions, option]);
                }
            } else if (["4", "5"].includes(option.id)) {
                // For options 4 and 5, they must be selected alone
                setSelectedOptionalOptions([option]);
            }
        }
    };

    // Increase/Decrease Quantity
    const increaseQuantity = () => setQuantity(quantity + 1);
    const decreaseQuantity = () => {
        if (quantity > 1) setQuantity(quantity - 1);
    };

    // Calculate total price
    const calculateTotalPrice = () => {
        let totalPrice = parseFloat(item.price);

        if (selectedRequiredOption) {
            totalPrice += parseFloat(selectedRequiredOption.option_price);
        }

        selectedOptionalOptions.forEach((option) => {
            totalPrice += parseFloat(option.option_price);
        });

        return totalPrice * quantity;
    };

    const handleAddToCart = () => {
        // Ensure that the required options and selected optional options are arrays
        const requiredOptions = item.required_options || [];
        const optionalOptions = selectedOptionalOptions || [];

        // Check if a required option is selected, if applicable
        if (requiredOptions.length > 0 && !selectedRequiredOption) {
            setError('Please select an option.');
            return; // Stop the function if a required option is not selected
        }

        setError(''); // Clear any previous errors

        // Construct the item object with all necessary details
        const newItem = {
            ...item, // Spread the original item data (name, description, image, etc.)
            selectedRequiredOption, // Include the selected required option, if any
            selectedOptionalOptions: optionalOptions, // Include selected optional options
            quantity, // The quantity selected in the modal
            totalPrice: calculateTotalPrice(), // The total price after calculating options and quantity
        };

        // Add the new item to the cart
        addToCart(newItem);

        // Close the modal immediately after adding the item to the cart
        onClose();

        // Trigger the success message to show after the modal closes
        onSuccess();
    };

    // Determine if the item is Sold Out
    const isOutOfStock = Number(item.in_stock) === 0;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-top">
                    <h2>{item.item_name}</h2>
                    <button className="close-modal-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <img
                    src={`/uploads/${item.image}`}
                    alt={item.item_name}
                    className="modal-item-image"
                />

                <p className="modal-item-desc">{item.item_description}</p>

                {/* Display Sold Out ticker */}
                {isOutOfStock && (
                    <div className="out-of-stock-ticker">
                        <span>Sold Out</span>
                    </div>
                )}

                {/* Required Options */}
                {item.required_options.length > 0 && (
                    <div className="modal-section">
                        <div className='modal-section-heading'>
                            <h4>Select one:</h4>
                            <span className='required-text'>required</span>
                        </div>
                        {item.required_options.map((option) => (
                            <label key={option.id} className="option-label">
                                <input
                                    type="radio"
                                    name="requiredOption"
                                    value={option.id}
                                    onChange={() => handleRequiredChange(option)}
                                    disabled={isOutOfStock}
                                />
                                <span>{option.option_name} (£{parseFloat(option.option_price).toFixed(2)})</span>
                            </label>
                        ))}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                )}

                {/* Optional Options */}
                {item.optional_options.length > 0 && (
                    <div className="modal-section">
                        <div className='modal-section-heading'>
                            <h4 className='options-heading'>Select one or more:</h4>
                            <span className='required-text'>optional</span>
                        </div>
                        {item.optional_options.map((option) => {
                            const optionId = parseInt(option.id, 10);
                            const selectedIds = selectedOptionalOptions.map(opt => parseInt(opt.id, 10));
                            const selectedCount = selectedOptionalOptions.filter(opt => [1, 2, 3].includes(parseInt(opt.id, 10))).length;

                            const isDisabled =
                                // Disable all options if 4 or 5 is selected
                                (selectedIds.includes(4) && ![4].includes(optionId)) ||
                                (selectedIds.includes(5) && ![5].includes(optionId)) ||
                                // Disable 4 and 5 if any of 1-3 are selected
                                ([4, 5].includes(optionId) && selectedCount > 0) ||
                                // Disable if two options from 1-3 are already selected
                                ([1, 2, 3].includes(optionId) && selectedCount === 2 && !selectedIds.includes(optionId));

                            return (
                                <label key={optionId} className="option-label">
                                    <input
                                        type="checkbox"
                                        value={optionId}
                                        onChange={() => handleOptionalChange(option)}
                                        disabled={isDisabled || isOutOfStock}
                                        checked={selectedIds.includes(optionId)}
                                    />
                                    <span>{option.option_name} (£{parseFloat(option.option_price).toFixed(2)})</span>
                                </label>
                            );
                        })}
                    </div>
                )}

                <div className='modal-bottom'>
                    {/* Quantity Selector */}
                    <div className="quantity-section">
                        <button onClick={decreaseQuantity} className="quantity-btn" disabled={isOutOfStock}>-</button>
                        <span className={`quantity-value ${isOutOfStock ? "disabled" : ""}`}>
                            {quantity}
                        </span>
                        <button onClick={increaseQuantity} className="quantity-btn" disabled={isOutOfStock}>+</button>
                    </div>


                    {/* Add to Cart Button */}
                    <button className="add-to-cart-button" onClick={handleAddToCart} disabled={isOutOfStock}>
                        Add to Cart for £{calculateTotalPrice().toFixed(2)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ItemModal;
