import React, { useState, useEffect } from 'react';
import './AgeConfirmationModal.css';

const AgeConfirmationModal = ({ onConfirmAge }) => {
    const [stage, setStage] = useState(1); 

    const handleYes = () => {
        setStage(2); 
    };

    const handleNo = () => {
        setStage(3); 
    };

    const handleOk = () => {
        onConfirmAge(true); 
    };

    // Reset to initial stage after confirmation or timeout
    useEffect(() => {
        if (stage === 3) {
            const timer = setTimeout(() => {
                onConfirmAge(false); 
            }, 3000);
            return () => clearTimeout(timer); 
        }
    }, [stage, onConfirmAge]);

    return (
        <div className="age-confirmation-modal-container">
            <div className="age-confirmation-modal-content">
                {stage === 1 && (
                    <div className="age-stage-1">
                        <p className="age-confirmation-text">Confirm you are above 18 to proceed</p>
                        <button onClick={handleYes} className="confirm-button">Yes</button>
                        <button onClick={handleNo} className="decline-button">No</button>
                    </div>
                )}

                {stage === 2 && (
                    <div className="age-stage-2">
                        <p className="age-warning-text">You might be required to provide ID on pick up.</p>
                        <button onClick={handleOk} className="confirm-button">OK</button>
                    </div>
                )}

                {stage === 3 && (
                    <div className="age-stage-3">
                        <p className="age-warning-text">You are underage and cannot buy alcohol.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AgeConfirmationModal;
