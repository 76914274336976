// src/pages/PrivateEvents.js
import React from 'react';
import './PrivateEvents.css';
import weddingImage from '../assets/wedding.jpg';
import corporateImage from '../assets/corporate.webp';
import birthdayImage from '../assets/birthday.jpg';
import diningImage from '../assets/dining.webp';
import listeningImage from '../assets/listening-party.jpeg';


function PrivateEventsPage() {
    return (
        <div className="private-events-page">
            {/* Introductory Section */}
            <section className="intro-text">
                <h1>Private Events at LKO</h1>
                <p>
                    Make your celebrations unforgettable with LKO Restaurant. From corporate gatherings to intimate parties or a grand celebration, we have the ideal setting for you. Let us make your event unforgettable. <br></br><br></br>
                    Get in touch today, and let’s start planning together.
                </p>
                <a className="cta-button primary-btn inquire-button" href="/contact">Inquire Now</a>
            </section>

            {/* Event Offerings Section */}
            <section className="event-offerings">
                <div className="offerings-list">
                    <div className="offering">
                        <img src={weddingImage} alt="Wedding Reception" className="offering-image" />
                        <h3>Wedding Receptions</h3>
                        <p>Celebrate your special day with elegant decor, tailored menus, and an atmosphere designed for memories.</p>
                    </div>
                    <div className="offering">
                        <img src={corporateImage} alt="Corporate Function" className="offering-image" />
                        <h3>Corporate Functions</h3>
                        <p>Host your next corporate event with us, complete with custom setups and a seamless experience from start to finish.</p>
                    </div>
                    <div className="offering">
                        <img src={listeningImage} alt="Listening Party" className="offering-image" />
                        <h3>Listening Parties</h3>
                        <p>Enjoy an intimate setting with acoustics and ambiance perfect for album releases and private listening sessions.</p>
                    </div>
                    <div className="offering">
                        <img src={birthdayImage} alt="Birthday Celebration" className="offering-image" />
                        <h3>Birthday Celebrations</h3>
                        <p>Make birthdays memorable with customized themes, vibrant decor, and a space filled with fun and excitement.</p>
                    </div>
                    <div className="offering">
                        <img src={diningImage} alt="Private Dining" className="offering-image" />
                        <h3>Private Dining</h3>
                        <p>Enjoy an exclusive dining experience in a cozy setting, perfect for small gatherings with a personalized menu.</p>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default PrivateEventsPage;
