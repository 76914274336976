// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BookTablePage from './pages/BookTablePage';
import PrivateEventsPage from './pages/PrivateEventsPage';
import OrderPage from './pages/OrderPage';
import ContactPage from './pages/ContactPage';
import ThankYouPage from './pages/ThankYouPage';
import CartModal from './components/CartModal';
import { CartProvider } from './context/CartContext';
import ScrollToTop from './components/ScrollToTop';

function App() {
    return (
        <CartProvider>
            <Router>
            <ScrollToTop />
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/book-table" element={<BookTablePage />} />
                    <Route path="/private-events" element={<PrivateEventsPage />} />
                    <Route path="/order-online" element={<OrderPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/thank-you" element={<ThankYouPage />} />  {/* Add ThankYouPage route */}
                </Routes>
                <Footer />
                {/* Cart Modal will be accessible globally */}
                <CartModal />
            </Router>
        </CartProvider>
    );
}

export default App;
