import React from 'react';
import './ThankYouPage.css'; 

const ThankYouPage = () => {
  return (
    <div className="thank-you-page">
      <h1>Thank you for getting in touch with LKO Restaurant!</h1>
      <h3>Your submission has been received.</h3>
      <h4>You'll be contacted soon.</h4>
      <br />
      <a href="/">Return to Home</a>
    </div>
  );
};

export default ThankYouPage;
