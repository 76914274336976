import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';
import { useCart } from '../context/CartContext';
import CartModal from './CartModal';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const { cartItems } = useCart();


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleCartModal = () => {
        setIsCartModalOpen(!isCartModalOpen);

    };


    // Get the number of items in the cart
    const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

    return (
        <>
            <nav className="navbar">
                <NavLink to="/" onClick={closeMenu}>
                    <img src={logo} alt="lkofoods-logo" className="navbar-logo" />
                </NavLink>

                <div className="navbar-toggle" onClick={toggleMenu}>
                    <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                </div>
                <ul className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
                    <li>
                        <NavLink
                            to="/"
                            end
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? "selected" : "")}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/book-table"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? "selected" : "")}
                        >
                            Book A Table
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/private-events"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? "selected" : "")}
                        >
                            Private Events
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/order-online"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? "selected" : "")}
                        >
                            Order Online
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/contact"
                            onClick={closeMenu}
                            className={({ isActive }) => (isActive ? "selected" : "")}
                        >
                            Contact
                        </NavLink>
                    </li>
                </ul>
                <div className="navbar-cart">
                    <NavLink to="#" onClick={toggleCartModal}>  {/* Trigger the modal on click */}
                        <i className="fas fa-shopping-cart cart-btn"></i>
                        <span className="cart-counter1">{cartItemCount}</span> {/* Dynamically show the cart item count */}
                    </NavLink>
                </div>
            </nav>

            {/* Render CartModal when isCartModalOpen is true */}
            <CartModal
                isOpen={isCartModalOpen}
                cartItems={cartItems}
                onClose={() => {
                    setIsCartModalOpen(false);
                }}
            />

            <div className="floating-cart cart-container">
                <NavLink to="#" onClick={toggleCartModal}> {/* Trigger the modal on click */}
                    <i className="fas fa-shopping-cart cart-btn"></i>
                    <span className="cart-counter2">{cartItemCount}</span> {/* Dynamically show the cart item count */}
                </NavLink>
            </div>
        </>
    );
}

export default Navbar;
